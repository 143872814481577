body {
  overflow-x: hidden;
}
.home {
  width: 100%;
  align-items: center;
  font-family: "Arial", sans-serif;
  color: #3e497a;
}

.about {
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #21325e;
  color: #f0f0f0;
}

.about h2 {
  margin-top: 5px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 80px;
  color: #e9d35b;
  height: 50px;
}

.profile-container {
  position: relative;
  display: inline-block;
}

.profile-image {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

.profile-image img {
  width: 172px;
  height: 172px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #3e497a;
}

.tooltip {
  display: none;
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #21325e;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
  z-index: 10;
  text-shadow: 1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000,
    -1px 1px 0 #000;
}

.profile-container:hover .tooltip {
  display: block;
}

.name {
  font-size: 40px;
  color: #333;
  animation: colorChange 3s infinite;
}

@keyframes colorChange {
  0% {
    color: #ff7e5f;
  }
  50% {
    color: #feb47b;
  }
  100% {
    color: #ff7e5f;
  }
}

.about .prompt {
  width: 70%;
  font-size: 30px;
  margin-top: 10px;
}

.prompt p {
  margin-top: 0;
}

.prompt svg {
  font-size: 60px;
  margin: 5px;
  color: white;
}

.skills-down-arrow {
  font-size: 90px;
  color: white;
  cursor: pointer;
  margin-top: 20px;
  transition: transform 0.2s ease-in-out;
}

.skills-down-arrow:hover {
  transform: scale(1.2);
}

.skills {
  font-size: 35px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.skills h1 {
  color: #3e497a;
  margin-bottom: 10px;
}

.skills .list {
  list-style: none;
  width: 60%;
  padding: 0;
}

.skills .list li {
  margin-top: 5px;
}

.skills .list h2 {
  margin-top: 0;
  margin-bottom: 20px;
}

.list span {
  font-size: 20px;
}

.footer-down-arrow-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.footer-down-arrow {
  font-size: 90px;
  color: black;
  cursor: pointer;
  margin-top: 20px;
  transition: transform 0.2s ease-in-out;
}

.footer-down-arrow:hover {
  transform: scale(1.2);
}

/*---------------MEDIA QUERIES------------------------*/

@media only screen and (max-width: 1280px) {
  .tooltip {
    display: none !important;
  }

  .about h2 {
    font-size: 60px;
  }

  .about .prompt {
    font-size: 24px;
    /* width: 80%; */
    max-width: 100%;
    margin-top: 20px;
  }

  .about {
    /* padding: 20px; */
    flex-direction: column;
  }

  .profile-container,
  .name,
  .prompt {
    margin-bottom: 20px;
    width: 100%;
    box-sizing: border-box;
  }
}

@media only screen and (max-width: 1120px) {
  .profile-image img {
    width: 135px;
    height: 135px;
  }
}

@media only screen and (max-width: 965px) {
  .name {
    font-size: 50px !important;
  }

  .profile-image img {
    width: 120px;
    height: 120px;
  }

  .prompt {
    font-size: 20px !important;
  }
}

/*----------NAVBAR RESUME ISSUE-----------------*/

/* @media only screen and (max-width: 905px) {
  .navbar a {
    font-size: 20px !important; 
/* padding: 8px 15px; 
/* }
} */

@media only screen and (max-width: 805px) {
  .name {
    font-size: 40px !important;
    /* color: red; */
  }

  .prompt {
    font-size: 18px !important;
    /* color: red; */
  }
}

@media only screen and (max-width: 640px) {
  .name {
    font-size: 35px !important;
  }

  .prompt {
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 600px) {
  .about h2 {
    font-size: 40px;
  }

  .about .prompt {
    margin-top: 10px;
    font-size: 20px;
    width: 80%;
  }

  .skills {
    text-align: center;
  }
  .list {
    /* padding: 0; */
    width: 100%;
  }

  .skills h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 560px) {
  .name {
    font-size: 33px !important;
  }

  .prompt {
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 530px) {
  .prompt {
    margin-top: 20px !important;
  }
}
