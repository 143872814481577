.project {
  width: 100%;
  /* height: calc(100vh - 100px); */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* min-height: calc(100vh - 100px); Ensures content isn't cut off */
  padding-top: 100px; /* Adjust this value to match the header height */
  box-sizing: border-box; /* Ensures padding is included in height calculation */
}

.project h1 {
  margin-top: 10px;
  color: #3e497a;
  font-size: 35px;
}

.project img {
  width: 600px; /* Adjust this value to your desired width */
  max-width: 100%; /* Ensures the image doesn't overflow the container */
  height: auto; /* Maintains the aspect ratio */
  border-radius: 10px;
}

/* .project img[src*="advanced-state-wheel-quiz-form-site-image.png"] {
  width: 200px;
  max-width: 100%;
  height: auto;
  border-radius: 10px;
} */

.project p {
  font-size: 40px;
  color: #3e497a;
}

.project svg {
  font-size: 60px;
  color: #3e497a;
}
